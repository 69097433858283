// modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsRoutingModule } from './projects-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { IconsProviderModule } from '../icons-provider.module';
import { AngularEditorModule } from '@kolkov/angular-editor';

// components
import { PostDisplayComponent } from './components/post-display/post-display.component';
import { PostFormComponent } from './components/post-form/post-form.component';
import { PostModalComponent } from './components/post-modal/post-modal.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProjectFormComponent } from './components/project-form/project-form.component';
import { ProjectViewComponent } from './pages/project-view/project-view.component';
import { TagRendererComponent } from './components/tag-renderer/tag-renderer.component';
import { WorksheetComponent } from './pages/worksheet/worksheet.component';
import { SendMailFormComponent } from './components/send-mail-form/send-mail-form.component';
import { MailingListModalComponent } from './components/mailing-list-modal/mailing-list-modal.component';

// ng-zorro modules
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { MailingListFormComponent } from './components/mailing-list-form/mailing-list-form.component';

@NgModule( {
  declarations: [
    ProjectsComponent,
    ProjectFormComponent,
    TagRendererComponent,
    ProjectViewComponent,
    PostFormComponent,
    PostDisplayComponent,
    PostModalComponent,
    WorksheetComponent,
    SendMailFormComponent,
    MailingListModalComponent,
    MailingListFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconsProviderModule,
    AngularEditorModule,
    NzButtonModule,
    NzCardModule,
    NzCollapseModule,
    NzDatePickerModule,
    NzFormModule,
    NzInputModule,
    NzListModule,
    NzMenuModule,
    NzModalModule,
    NzPageHeaderModule,
    NzPopoverModule,
    NzSelectModule,
    NzSwitchModule,
    NzTagModule,
    NzToolTipModule,
    SharedModule,
    ProjectsRoutingModule,
  ]
} )
export class ProjectsModule { }
