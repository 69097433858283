import { AuthService } from '@signature/webfrontauth';
import { CrsService } from './core/crs/crs.service';
import { AgGridLicenceRegistrationService } from './core/services/ag-grid-licence-registration.service';

// tslint:disable-next-line: typedef
export function initializeApplication(
  crsService: CrsService,
  authService: AuthService,
  agGridLicenceService: AgGridLicenceRegistrationService
) {
  return async () => {
    // if ( authService.currentError?.error ) {
    //   throw new Error( 'Angular was not able to initialize AuthService for the application.' );
    // }

    await crsService.initialize();
    authService.addOnChange( () => crsService.reloadMetadata() );

    await authService.refresh( false, true, true );

    agGridLicenceService.setLicenceKey();
  };
}
