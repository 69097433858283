<nz-sider 
  class="menu-sidebar"
  nzWidth="256px"
  nzBreakpoint="md">
  <div class="menu">
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-menu-item nzMatchRouter>
        <i nz-icon nzType="dashboard" nzTheme="outline"></i>
        <span class="item-class">Vue d'ensemble</span>
      </li>
      <li nz-menu-item nzMatchRouter [nzSelected]="isSelected( '/projects' )" routerLink="/projects">
        <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
        <span class="item-class">Projets</span>
      </li>
      <li *ngIf="isAdmin()" nz-menu-item [nzSelected]="isSelected( '/admin' )" routerLink="/admin" class="admin-item">
        <i nz-icon nzType="setting" nzTheme="outline"></i>
        <span>Administration</span>
      </li>
    </ul>
  </div>
</nz-sider>