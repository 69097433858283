import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from '@signature/webfrontauth';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CrsService } from '../../../core/crs/crs.service';
import { DefaultCommandResponse } from 'src/app/core/crs/response.model';
import { SetUserPasswordCommand } from '../../commands/set-user-password.command';

@Component( {
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less']
} )
export class ProfileComponent implements OnInit {
  public pwForm: FormGroup;
  public showCurrentPassword: boolean;
  public showPassword: boolean;
  public showRepeatPassword: boolean;

  constructor (
    private _formBuilder: FormBuilder,
    private _crsService: CrsService,
    private _authService: AuthService,
    private _nzMessageService: NzMessageService
  ) {
    this.showCurrentPassword = false;
    this.showPassword = false;
    this.showRepeatPassword = false;
    this.pwForm = this._formBuilder.group( {
      newPw: ['', Validators.required],
      repeatPw: ['', Validators.required]
    },
      {
        validator: this.areEquals( 'newPw', 'repeatPw' ),
      } );
  }

  ngOnInit(): void {
  }

  areEquals = ( pathA: string, pathB: string, errorKey: string = 'missmatch' ): ValidatorFn => {
    return ( abstractControl: AbstractControl ): ValidationErrors | null => {
      const abstractControlA = abstractControl.get( pathA );
      const abstractControlB = abstractControl.get( pathB );

      if ( abstractControlA && abstractControlB ) {
        const valueA = abstractControlA.value;
        const valueB = abstractControlB.value;

        if ( valueA !== null && valueA !== undefined && valueA === valueB ) {
          abstractControlB.setErrors( null );
          return null;
        }

        const error = this.generateError( errorKey );
        abstractControlB.setErrors( error );
        return error;
      }
      return null;
    };
  }

  generateError = ( errorKey: string ) => {
    const validationError: any = {};
    validationError[errorKey] = true;
    return validationError;
  }

  submitForm(): void {
    if ( this.pwForm.valid ) {
      const command = new SetUserPasswordCommand(
        this._authService.authenticationInfo.actualUser.userId,
        this.pwForm.get( 'newPw' )!.value
      );

      this._crsService.send<DefaultCommandResponse>( command ).then( res => {
        if ( res ) {
          this._nzMessageService.success( 'La modification a été prise en compte.' );
        } else {
          this._nzMessageService.error( 'Une erreur est survenue lors de la modification.' );
        }
        this.pwForm.reset();
      } );
    }
  }

  resetForm(): void {
    this.pwForm.reset();
  }
}
