import { Inject, Injectable } from '@angular/core';
import { CONFIG } from '../config/injectionConfig';
import { IWebSettings } from '../config/websettings.model';
import { LicenseManager } from 'ag-grid-enterprise';

@Injectable( {
  providedIn: 'root'
} )
export class AgGridLicenceRegistrationService {
  private _key: string;

  constructor (
    @Inject( CONFIG ) config: IWebSettings
  ) {
    this._key = config.agGridLicenceKey;
  }

  setLicenceKey(): void {
    LicenseManager.setLicenseKey( this._key );
  }
}
