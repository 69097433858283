import { Command } from "@signature/crs-client";

@Command( 'ChangeUserGroup' )
export class ChangeMemberGroupCommand {
    constructor (
        public actorId: number,
        public projectId: number,
        public groupId: number,
        public userId: number
    ) {
    }
}