import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IServerSideGetRowsRequest } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { GroupInfos, User } from 'src/app/admin/shared/models/user.model';
import { ActionStatus, MailingList, MailTemplate, MemberEmail, Post, PostType, Project, ProjectData, ProjectStatus, ProjectWithPosts } from 'src/app/projects/shared/models/project.model';
import { DbViewResult } from 'src/app/shared/models/ag-grid.model';
import { CONFIG } from '../config/injectionConfig';
import { IWebSettings } from '../config/websettings.model';

@Injectable( {
  providedIn: 'root'
} )
export class ProjectApiService {
  private _baseUrl: string;

  constructor (
    private _httpClient: HttpClient,
    @Inject( CONFIG ) config: IWebSettings
  ) {
    this._baseUrl = `${config.apiRoot}/project`;
  }

  public getAllProjects( rq: IServerSideGetRowsRequest ): Observable<DbViewResult<Project>> {
    const params = new HttpParams().set( 'req', JSON.stringify( rq ) );
    return this._httpClient.get<DbViewResult<Project>>( `${this._baseUrl}/aggrid-projects`, { params: params } );
  }

  public getProjects(): Observable<Array<Project>> {
    return this._httpClient.get<Array<Project>>( `${this._baseUrl}/projects` );
  }

  public getProjectData( projectId: number ): Observable<ProjectData> {
    return this._httpClient.get<ProjectData>( `${this._baseUrl}/${projectId}/project-data` );
  }

  public getPostTypes(): Observable<Array<PostType>> {
    return this._httpClient.get<Array<PostType>>( `${this._baseUrl}/post-types` );
  }

  public getPost( postId: number ): Observable<Post> {
    return this._httpClient.get<Post>( `${this._baseUrl}/${postId}/post` );
  }

  public getProjectStatus(): Observable<Array<ProjectStatus>> {
    return this._httpClient.get<Array<ProjectStatus>>( `${this._baseUrl}/project-status` );
  }

  public getProjectGroups( projectId: number ): Observable<Array<GroupInfos>> {
    return this._httpClient.get<Array<GroupInfos>>( `${this._baseUrl}/${projectId}/groups` );
  }

  public getPotentialMembers( projectId: number ): Observable<Array<User>> {
    return this._httpClient.get<Array<User>>( `${this._baseUrl}/${projectId}/potential-members` );
  }

  public getAllActionStatus(): Observable<Array<ActionStatus>> {
    return this._httpClient.get<Array<ActionStatus>>( `${this._baseUrl}/allActionStatus` );
  }

  public getProjectWithPosts( projectId: number, startDate: Date, endDate: Date ): Observable<ProjectWithPosts> {
    return this._httpClient.get<ProjectWithPosts>( `${this._baseUrl}/${projectId}/${startDate.toJSON()}/${endDate.toJSON()}/posts` );
  }

  public getAllMailTemplates(): Observable<Array<MailTemplate>> {
    return this._httpClient.get<Array<MailTemplate>>( `${this._baseUrl}/mail-templates` );
  }

  public getMembersEmail( projectId: number ): Observable<Array<MemberEmail>> {
    return this._httpClient.get<Array<MemberEmail>>( `${this._baseUrl}/${projectId}/members-email` );
  }

  public getMailingLists( projectId: number ): Observable<Array<MailingList>> {
    return this._httpClient.get<Array<MailingList>>( `${this._baseUrl}/${projectId}/mailing-lists` );
  }
}
