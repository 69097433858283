import { Inject, Injectable } from '@angular/core';
import { CrsEndpoint, CrsEndpointConfiguration } from '@signature/crs-client';
import { SignalrResponseReceiver } from '@signature/crs-client-signalr';
import { AXIOS } from '@signature/webfrontauth-ngx';
import { AxiosInstance } from 'axios';
import { CONFIG } from '../config/injectionConfig';
import { IWebSettings } from '../config/websettings.model';

@Injectable( {
  providedIn: 'root'
} )
export class CrsService extends CrsEndpoint {
  constructor (
    @Inject( CONFIG ) configuration: IWebSettings,
    @Inject( AXIOS ) axios: AxiosInstance
  ) {
    const endpointConfig: CrsEndpointConfiguration = {
      url: configuration.crs.endpoint,
      responseReceivers: [
        // new SignalrResponseReceiver( configuration.crs.signalrEndpoint )
      ],
      axiosInstance: axios
    };
    super( endpointConfig );
  }
}
