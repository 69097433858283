// modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectsModule } from '../projects/projects.module';
import { IconsProviderModule } from '../icons-provider.module';
import { SharedModule } from '../shared/shared.module';

// components
import { HomePageComponent } from './pages/home-page/home-page.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ProfileComponent } from './pages/profile/profile.component';

// ng-zorro modules
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSwitchModule } from 'ng-zorro-antd/switch';


@NgModule( {
  declarations: [
    HomePageComponent,
    SidebarComponent,
    TopbarComponent,
    ErrorPageComponent,
    ProfileComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconsProviderModule,
    NzAvatarModule,
    NzButtonModule,
    NzDropDownModule,
    NzFormModule,
    NzInputModule,
    NzLayoutModule,
    NzMenuModule,
    NzResultModule,
    NzSwitchModule,
    HomeRoutingModule,
    ProjectsModule,
    SharedModule
  ]
} )
export class HomeModule { }
