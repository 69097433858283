import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './home/home.module';
import { AuthenticationModule } from './authentication/authentication.module';

import { AppComponent } from './app.component';
import { fr_FR, NZ_I18N } from 'ng-zorro-antd/i18n';
import { initializeApplication } from './app.initializer';
import { CrsService } from './core/crs/crs.service';
import { AuthInterceptor, NgxAuthModule } from '@signature/webfrontauth-ngx';
import { AuthService } from '@signature/webfrontauth';
import { AgGridLicenceRegistrationService } from './core/services/ag-grid-licence-registration.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgGridModule } from 'ag-grid-angular';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';

// registerLocaleData( en );
registerLocaleData( localeFr );

@NgModule( {
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AgGridModule.withComponents( [] ),
    NgxAuthModule.forRoot(),
    HttpClientModule,
    FormsModule,

    AuthenticationModule,
    HomeModule,
    AppRoutingModule
  ],
  providers: [
    {
      // Initialize CRS service
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      deps: [CrsService, AuthService, AgGridLicenceRegistrationService],
      multi: true
    },
    {
      // Authenticates all HTTP requests made by Angular
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: NZ_I18N, useValue: fr_FR }
  ],
  bootstrap: [AppComponent]
} )
export class AppModule { }
