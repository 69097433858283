<div class="posts">
    <div class="list-container">
        <nz-card class="card" nzTitle="Informations">
            <nz-list>
                <nz-list-item *ngFor="let p of displayedInfos" class="post-content" (dblclick)="openPostModal(p)">
                    <div class="post-title">
                        <span>#{{p.postId}} {{ p.title }}</span>
                        <span class="owner"> - {{ p.ownerUserName }} le {{ formatDate( p.creationDate ) }}</span>
                    </div>
                    <ng-container *ngIf="p.isImportant">
                        <nz-tag nzColor="error">
                            <i nz-icon nzType="warning" nzTheme="outline" nz-tooltip="Important"></i>
                        </nz-tag>
                    </ng-container>
                    <ng-container *ngIf="p.isArchived">
                        <nz-tag nzColor="default">
                            <i nz-icon nzType="delete" nzTheme="outline" nz-tooltip="Archivé"></i>
                        </nz-tag>
                    </ng-container>
                    <ng-container *ngIf="p.isPrivate">
                        <nz-tag nzColor="error">
                            <i nz-icon nzType="eye-invisible" nzTheme="outline"></i>
                        </nz-tag>
                    </ng-container>
                </nz-list-item>
            </nz-list>
        </nz-card>
        <nz-card class="card" nzTitle="Décisions">
            <nz-list>
                <nz-list-item *ngFor="let p of displayedDecisions" class="post-content" (dblclick)="openPostModal(p)">                    
                    <div class="post-title">
                        <span>#{{p.postId}} {{ p.title }}</span>
                        <span class="owner"> - {{ p.ownerUserName }} le {{ formatDate( p.creationDate ) }}</span>
                    </div>
                    <ng-container *ngIf="p.isImportant">
                        <nz-tag nzColor="error">
                            <i nz-icon nzType="warning" nzTheme="outline" nz-tooltip="Important"></i>
                        </nz-tag>
                    </ng-container>
                    <ng-container *ngIf="p.isArchived">
                        <nz-tag nzColor="default">
                            <i nz-icon nzType="delete" nzTheme="outline" nz-tooltip="Archivé"></i>
                        </nz-tag>
                    </ng-container>
                    <ng-container *ngIf="p.isPrivate">
                        <nz-tag nzColor="error">
                            <i nz-icon nzType="eye-invisible" nzTheme="outline"></i>
                        </nz-tag>
                    </ng-container>
                </nz-list-item>
            </nz-list>
        </nz-card>
        <nz-card class="card" nzTitle="Actions">
            <nz-list>
                <nz-list-item *ngFor="let p of displayedActions" class="post-content" (dblclick)="openPostModal(p)">
                    <div class="post-title">
                        <div class="title-container">
                            <span>#{{ p.postId }} {{ p.title }}</span>
                            <div [ngSwitch]="p.actionStatusId" class="action-status">
                                <ng-container *ngIf="p.isPrivate">
                                    <nz-tag nzColor="error">
                                        <i nz-icon nzType="eye-invisible" nzTheme="outline"></i>
                                    </nz-tag>
                                </ng-container>
                                <ng-container *ngSwitchCase="actionTypes.PENDING">
                                    <nz-tag nzColor="default">
                                        {{ p.actionStatusText }}
                                    </nz-tag>
                                </ng-container>
                                <ng-container *ngSwitchCase="actionTypes.ONGOING">
                                    <nz-tag [nzColor]="'gold'">
                                        {{ p.actionStatusText }}
                                    </nz-tag>
                                </ng-container>
                                <ng-container *ngSwitchCase="actionTypes.COMPLETED">
                                    <nz-tag [nzColor]="'blue'">
                                        {{ p.actionStatusText }}
                                    </nz-tag>
                                </ng-container>
                                <ng-container *ngSwitchCase="actionTypes.CANCELED">
                                    <nz-tag nzColor="error">
                                        {{ p.actionStatusText }}
                                    </nz-tag>
                                </ng-container>
                            </div>
                        </div>
                        <span class="owner"> - {{ p.ownerUserName }} le {{ formatDate( p.creationDate ) }}</span>
                    </div>
                    <ng-container *ngIf="p.isImportant">
                        <nz-tag nzColor="error">
                            <i nz-icon nzType="warning" nzTheme="outline" nz-tooltip="Important"></i>
                        </nz-tag>
                    </ng-container>
                    <ng-container *ngIf="p.isArchived">
                        <nz-tag nzColor="default">
                            <i nz-icon nzType="delete" nzTheme="outline" nz-tooltip="Archivé"></i>
                        </nz-tag>
                    </ng-container>
                </nz-list-item>
            </nz-list>
        </nz-card>
    </div>
</div>

<ng-container *ngIf="selectedPost">
    <app-post-modal 
        [post]="selectedPost"
        [isVisible]="showPostModal"
        (modalClosed)="closeModal()"
    ></app-post-modal>
</ng-container>