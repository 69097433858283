import { Command } from "@signature/crs-client";

@Command( 'EditComment' )
export class EditCommentCommand {
    constructor (
        public actorId: number,
        public commentId: number,
        public commentValue: string
    ) {
    }
}