<div class="mailing-list-form">
    <ng-container *ngIf="formGroup">
        <form nz-form [formGroup]="formGroup" class="form">
            <ng-container *ngIf="type && type === 'createList'; else addUser">
                <nz-form-item>
                    <nz-form-control>
                        <nz-form-label [nzRequired]="true">Nom de la liste</nz-form-label>
                        <nz-input-group>
                            <input formControlName="listName" nz-input />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </ng-container>
            <ng-template #addUser>
                <nz-form-item>
                    <nz-form-control>
                        <nz-form-label [nzRequired]="false">Utilisateurs</nz-form-label>
                        <nz-input-group>
                            <nz-select class="user-select" nzShowSearch nzAllowClear nzPlaceHolder="Sélectionner un ou plusieurs utilisateur" formControlName="users">
                                <ng-container *ngFor="let m of membersEmails">
                                    <nz-option [nzLabel]="getMemberLabel( m )" [nzValue]="m.email" [nzDisabled]="shouldDisable(m)"></nz-option>
                                </ng-container>
                            </nz-select>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control>
                        <nz-form-label [nzRequired]="false">Adresse e-mail</nz-form-label>
                        <nz-input-group>
                            <input formControlName="email" nz-input />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control>
                        <nz-form-label>Prénom</nz-form-label>
                        <nz-input-group>
                            <input formControlName="firstName" nz-input />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control>
                        <nz-form-label>Nom</nz-form-label>
                        <nz-input-group>
                            <input formControlName="lastName" nz-input />
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </ng-template>
        </form>
    </ng-container>
</div>