import { Command } from "@signature/crs-client";

@Command( 'SendPostByMail' )
export class SendPostByMailCommand {
    constructor (
        public actorId: number,
        public postId: number,
        public mailContent: string,
        public destination: Array<string>
    ) {
    }
}