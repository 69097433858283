import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../core/guards/auth-guard.service';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ProfileComponent } from './pages/profile/profile.component';

const routes: Routes = [
  {
    path: '', pathMatch: 'full', redirectTo: 'projects'
  },
  {
    path: '', component: HomePageComponent, canActivate: [AuthGuardService],
    children: [
      {
        path: 'projects', loadChildren: () => import( '../projects/projects.module' ).then( ( m ) => m.ProjectsModule )
      },
      {
        path: 'admin', loadChildren: () => import( '../admin/admin.module' ).then( m => m.AdminModule )
      },
      {
        path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService]
      }
    ]
  },
  {
    path: '**', pathMatch: 'full', component: ErrorPageComponent
  }
];

@NgModule( {
  imports: [RouterModule.forRoot( routes )],
  exports: [RouterModule]
} )
export class HomeRoutingModule { }
