import { Component, OnInit, Input } from '@angular/core';
import { TableAction } from '../../models/table-action.model';

@Component( {
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.less']
} )
export class ActionBarComponent<T> implements OnInit {
  @Input() actions: { left: Array<TableAction<T>>; mid: Array<TableAction<T>>, right: Array<TableAction<T>> };
  @Input() selectedItems: Array<T>;

  constructor () { }

  ngOnInit(): void {
  }

  buttonClicked( action: TableAction<T> ): void {
    action.execute( this.selectedItems );
  }

  shouldShow( action: TableAction<T> ): boolean {
    return action.shouldBeDisplayed( this.selectedItems );
  }
}
