import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, UrlTree, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@signature/webfrontauth';

@Injectable( {
  providedIn: 'root'
} )
export class AuthGuardService implements CanActivate {
  constructor(
    private _router: Router,
    private _authService: AuthService
  ) { }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot )
    : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      return this.checkAuth( route, state );
  }

  canActivateChild( childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot )
    : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      return this.checkAuth( childRoute, state );
  }

  async checkAuth( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Promise<boolean> {
    if ( this._authService.authenticationInfo.level >= 2 ) {
      return true;
    }

    this._router.navigate( ['/login'], { queryParams: { redirectUrl: '#' + state.url } } );
    return false;
  }
}


@Injectable( {
  providedIn: 'root'
} )
export class ReverseAuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot )
  : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if ( this.authService.authenticationInfo.level < 2 ) {
      return true;
    }

    this.router.navigate( [''], { queryParams: { redirectUrl: '#' + state.url }} );
    return false;
  }
}
