import { Command } from "@signature/crs-client";

@Command( 'EditProject' )
export class EditProjectCommand {
    constructor (
        public actorId: number,
        public projectId: number,
        public projectName: string,
        public description: string,
        public isPrivate: boolean
    ) {
    }
}