<div id="login-component">
    <form nz-form [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit(loginForm.value)">
      <nz-form-item class="login-form-item">
        <nz-form-control nzHasFeedback [nzErrorTip]="emailError">
          <nz-input-group nzPrefixIcon="user">
            <input nz-input name="email" type="email" id="email" formControlName="email"
            placeholder="Email ou nom d'utilisateur">
          </nz-input-group>
          <ng-template #emailError>
            <ng-container *ngIf="loginForm.get('email')!.hasError('required')">
              Veuillez renseigner un nom d'utilisateur
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="login-form-item">
        <nz-form-control nzHasFeedBack [nzErrorTip]="passwordError">
          <nz-input-group nzPrefixIcon="lock">
            <input nz-input type="password" name="password" id="password" formControlName="password"
            placeholder="Mot de passe">
          </nz-input-group>
          <ng-template #passwordError>
            <ng-container *ngIf="loginForm.get('password')!.hasError('required')">
              Veuillez renseigner un mot de passe
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <div nz-row>
        <label nz-checkbox formControlName="rememberMe">
          Mémoriser
        </label>
      </div>
      <br>
      <button nz-button [nzType]="'primary'">
        Se connecter
      </button>
   </form>

   <div class="external-providers">
     <button nz-button (click)="loginWithAAD()" nzType="primary">
       <i nz-icon nzType="signature:logo"></i>
       Se connecter avec Signature
     </button>
   </div>
  </div>
  