import { Component, OnInit, Input, TemplateRef, Type, Output, EventEmitter } from '@angular/core';
import { CrsCommand } from 'src/app/core/crs/command.model';
import { CrsService } from 'src/app/core/crs/crs.service';
import { ModalOptions, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { DefaultCommandResponse } from 'src/app/core/crs/response.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormGroup } from '@angular/forms';
import { ValidatedForm } from '../../models/form-validation.model';

@Component( {
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.less']
} )
export class GenericModalComponent implements OnInit {
  @Input() command: CrsCommand;
  @Output() actionExecuted = new EventEmitter<void>();
  @Output() formValidated = new EventEmitter<ValidatedForm>();

  _modalRef: NzModalRef;

  constructor (
    private _crsService: CrsService,
    private _nzModalService: NzModalService,
    private _nzMessageService: NzMessageService
  ) { }

  ngOnInit(): void {
  }

  openConfirmModal(
    title: string,
    successMsg: string,
    errorMsg: string,
    command: any
  ): void {
    const opts: ModalOptions = {
      nzTitle: title,
      nzOnOk: () => {
        this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
          if ( res.success ) {
            this._nzMessageService.success( successMsg );
          } else {
            this._nzMessageService.error( errorMsg );
          }

          this.actionExecuted.next();
        } );
      },
      nzOnCancel: () => {
        this.closeModal();
      }
    }

    this._modalRef = this._nzModalService.confirm( opts );
  }

  openFormModal(
    title: string,
    component: Type<any>,
    componentParams: Partial<{}>,
    actionName: string
  ): void {
    const opts: ModalOptions = {
      nzContent: component,
      nzComponentParams: componentParams,
      nzTitle: title,
      nzOnOk: (): boolean => {
        return this.validateForm( actionName );
      },
      nzOnCancel: () => {
        this.closeModal();
      }
    }

    this._modalRef = this._nzModalService.create( opts );
  }

  validateForm( actionName: string ): boolean {
    const form: FormGroup = this._modalRef.getContentComponent().getFormValue();
    if ( form.invalid ) {
      this._nzMessageService.error( 'Le formulaire est invalide.' );
      form.markAsDirty();
      Object.keys( form.controls ).forEach( c => form.get( c ).updateValueAndValidity() );
      return false;
    } else {
      this.formValidated.next( { form: form, action: actionName } );
      return true;
    }
  }

  closeModal(): void {
    this._nzModalService.openModals.find( m => m === this._modalRef ).close();
  }
}
