import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AuthServiceClientConfiguration, AXIOS } from '@signature/webfrontauth-ngx';

import { AppModule } from './app/app.module';
import { CONFIG } from './app/core/config/injectionConfig';
import { websettingsLoader } from './app/core/config/websettings.loader';
import { environment } from './environments/environment';
import axios from 'axios';


if ( environment.production ) {
  enableProdMode();
}

websettingsLoader()
  .then( ( config ) => {
    platformBrowserDynamic( [
      {
        provide: CONFIG,
        useValue: config
      },
      {
        provide: AuthServiceClientConfiguration,
        useValue: new AuthServiceClientConfiguration( config.webfrontauth.configuration.identityEndPoint, config.webfrontauth.loginPath ),
      },
      {
        provide: AXIOS,
        useValue: axios.create(),
      }
    ] ).bootstrapModule( AppModule )
      .catch( ( err ) => console.error( err ) );
  } );