import { Command } from "@signature/crs-client";

@Command( 'CreateComment' )
export class CreateCommentCommand {
    constructor (
        public actorId: number,
        public postId: number,
        public commentValue: string
    ) {
    }
}