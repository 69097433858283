import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ProjectApiService } from 'src/app/core/api/project-api.service';
import { PostType } from '../../shared/models/project.model';

@Component( {
  selector: 'app-post-form',
  templateUrl: './post-form.component.html',
  styleUrls: ['./post-form.component.less']
} )
export class PostFormComponent implements OnInit {
  @Input() postForm: FormGroup;
  @Input() selectedType: string = '';

  public postTypes: Array<PostType>;

  constructor (
    private _projectApiService: ProjectApiService
  ) {
    this.postTypes = new Array<PostType>();
  }

  ngOnInit(): void {
    this._projectApiService.getPostTypes().pipe( first() ).subscribe( types => {
      this.postTypes = [...types];

      if ( this.selectedType && this.selectedType.length > 0 ) {
        this.postForm.patchValue( {
          postType: this.postTypes.find( t => t.type === this.selectedType ).postTypeId
        } );
      }
    } );
  }

  getFormValue(): FormGroup {
    return this.postForm;
  }

  setError(): void {
    this.postForm.updateValueAndValidity();
  }
}
