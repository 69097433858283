import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@signature/webfrontauth';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ProjectApiService } from 'src/app/core/api/project-api.service';
import { CrsService } from 'src/app/core/crs/crs.service';
import { ErrorCommandResponse } from 'src/app/core/crs/error-response.model';
import { DefaultCommandResponse } from 'src/app/core/crs/response.model';
import { GenericModalComponent } from 'src/app/shared/components/generic-modal/generic-modal.component';
import { ValidatedForm } from 'src/app/shared/models/form-validation.model';
import { AddRecipientToMailingListCommand } from '../../shared/commands/project/add-recipient-to-mailing-list.command';
import { CreateMailingListCommand } from '../../shared/commands/project/create-mailing-list.command';
import { MailingList } from '../../shared/models/project.model';
import { MailingListFormComponent } from '../mailing-list-form/mailing-list-form.component';

@Component( {
  selector: 'app-mailing-list-modal',
  templateUrl: './mailing-list-modal.component.html',
  styleUrls: ['./mailing-list-modal.component.less']
} )
export class MailingListModalComponent implements OnInit {
  @ViewChild( 'modalComponent' ) modalComponent: GenericModalComponent;

  @Input() isVisible: boolean;

  get getProjectId(): number {
    return this._projectId;
  }
  @Input() set projectId( id: number ) {
    if ( typeof id !== 'undefined' ) {
      this._projectId = id;
      this.getMailingLists( id );
    }
  }
  @Output() modalClosed = new EventEmitter<void>();

  private _projectId: number;
  public mailingLists: Array<MailingList>;
  public selectedList: MailingList;

  constructor (
    private _formBuilder: FormBuilder,
    private _crsService: CrsService,
    private _nzMessageService: NzMessageService,
    private _authService: AuthService,
    private _projectApiService: ProjectApiService
  ) {
    this.mailingLists = new Array<MailingList>();
    this.selectedList = undefined;
  }

  ngOnInit(): void {
  }

  getMailingLists( projectId: number ): void {
    this._projectApiService.getMailingLists( projectId ).subscribe( ml => {
      this.mailingLists = [...ml];
      this.selectedList = this.mailingLists[0];
    } );
  }

  closeModal(): void {
    this.modalClosed.emit();
  }

  isSelected( listName: string ): boolean {
    return this.selectedList.mailingListName === listName;
  }

  setSelected( list: MailingList ): void {
    this.selectedList = list;
  }

  createListFormGroup(): FormGroup {
    return this._formBuilder.group( {
      listName: ['', Validators.required]
    } );
  }

  createMemberFormGroup(): FormGroup {
    return this._formBuilder.group( {
      userId: [0],
      email: ['', Validators.required],
      firstName: [''],
      lastName: [''],
      users: []
    } );
  }

  openCreateListModal(): void {
    const title = `Nouvelle liste`;
    const form: FormGroup = this.createListFormGroup();
    const componentParams = { formGroup: form, type: 'createList', projectId: this.getProjectId, selectedList: this.selectedList };
    this.modalComponent.openFormModal( title, MailingListFormComponent, componentParams, 'createMailingList' );
  }

  openAddMemberModal(): void {
    const title = `Nouveau destinataire`;
    const form: FormGroup = this.createMemberFormGroup();
    const componentParams = { formGroup: form, type: 'addUser', projectId: this.getProjectId, selectedList: this.selectedList };
    this.modalComponent.openFormModal( title, MailingListFormComponent, componentParams, 'addUserToMailingList' );
  }

  modalFormValidated( result: ValidatedForm ): void {
    if ( result.action === 'createMailingList' ) {
      if ( result.form.valid ) {
        const command = new CreateMailingListCommand(
          this._authService.authenticationInfo.user.userId,
          this.getProjectId,
          result.form.get( 'listName' ).value
        );

        this._crsService.send<DefaultCommandResponse>( command ).then( res => {
          if ( res.success ) {
            this._nzMessageService.success( 'La liste de diffusion a été créée avec succès.' );
          } else {
            this._nzMessageService.error( `${( res as ErrorCommandResponse ).errorMessage}` );
          }

          this.getMailingLists( this.getProjectId );
        } );
      }
    } else if ( result.action === 'addUserToMailingList' ) {
      var regexp = new RegExp( /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ );
      if ( !result.form.get( 'users' ).value && !result.form.get( 'email' ).value ) {
        this._nzMessageService.error( 'Vous devez renseigner un email ou selectionner un utilsateur.' );
      } else if ( !regexp.exec( result.form.get( 'email' ).value ) ) {
        this._nzMessageService.error( 'L\'email renseigné n\'est pas correct.' );
      } else {
        const command = new AddRecipientToMailingListCommand(
          this._authService.authenticationInfo.user.userId,
          this.selectedList.mailingListId,
          result.form.get( 'users' ).value,
          result.form.get( 'email' ).value,
          result.form.get( 'firstName' ).value,
          result.form.get( 'lastName' ).value,
        );

        this._crsService.send<DefaultCommandResponse>( command ).then( res => {
          if ( res.success ) {
            this._nzMessageService.success( `L'ajout s'est effectué avec succès.` );
          } else {
            this._nzMessageService.error( `${( res as ErrorCommandResponse ).errorMessage}` );
          }
          this.getMailingLists( this.getProjectId );
        } );
      }
    }
  }
}
