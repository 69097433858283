import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  CellDoubleClickedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GridReadyEvent,
  GridSizeChangedEvent,
  IServerSideDatasource,
  PaginationChangedEvent,
  SelectionChangedEvent,
  ServerSideStoreType
} from 'ag-grid-community';
import { AG_GRID_LOCALE_FR } from 'src/ag-grid.locale.fr';

@Component( {
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.less']
} )
export class GenericTableComponent<T> implements OnInit {
  @ViewChild( 'agGrid' ) agGrid!: AgGridAngular;
  @Input() serverSideDatasource!: IServerSideDatasource;
  @Input() hasDetailedView?: boolean = false;
  @Input() pageSize: number = 100;
  @Input() suppressRowClickSelection: boolean = false;
  @Output() selectionChanged = new EventEmitter<Array<T>>();
  @Output() doubleclicked = new EventEmitter<T>();

  public columns?: Array<ColDef>;
  public storeType: ServerSideStoreType;
  public rowData!: Array<T>;
  public localeText = AG_GRID_LOCALE_FR;

  constructor () {
    this.storeType = 'partial';
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  deselectAll(): void {
    this.agGrid!.api.deselectAll();
    this.selectionChanged.emit( [] );
  }

  applyGridConfig(): void {
    this.agGrid!.gridOptions.onFirstDataRendered = this.onFirstDataRendered;
    this.agGrid!.gridOptions.suppressRowClickSelection = this.suppressRowClickSelection;
    this.agGrid!.gridOptions.rowSelection = 'multiple';
    this.agGrid!.gridOptions.rowMultiSelectWithClick = true;
    this.agGrid!.paginationPageSize = this.pageSize;
    this.agGrid.gridOptions.cacheBlockSize = 100;
  }

  onFirstDataRendered( params: FirstDataRenderedEvent ): void {
    params.api.sizeColumnsToFit();
  }

  onPaginationChanged( params: PaginationChangedEvent ): void {
    this.deselectAll();
  }

  refresh(): void {
    this.agGrid.api.refreshServerSideStore( {
      purge: true
    } );
  }

  fixSize(): void {
    this.agGrid.api.sizeColumnsToFit();
  }

  onGridReady( params: GridReadyEvent ): void {
    this.applyGridConfig();
    this.agGrid.api.setServerSideDatasource( this.serverSideDatasource );

    if ( this.hasDetailedView ) {
      this.agGrid!.gridOptions.onCellDoubleClicked = ( event: CellDoubleClickedEvent ) => {
        this.doubleclicked.emit( event.data );
      }
    }

    this.agGrid!.gridOptions.onGridSizeChanged = ( e: GridSizeChangedEvent ) => {
      e.api.sizeColumnsToFit();
    }

    this.agGrid!.gridOptions.onSelectionChanged = ( event: SelectionChangedEvent ) => {
      const selected = event.api.getSelectedRows();
      this.selectionChanged.emit( selected );
    }

    this.agGrid!.gridOptions.onPaginationChanged = this.onPaginationChanged.bind( this );

    this.agGrid!.gridOptions.context = {
      parentComponent: this
    };
  }
}
