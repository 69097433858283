import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserAggregate } from 'src/app/admin/shared/models/user.model';
import { UserService } from 'src/app/shared/services/user.service';

@Component( {
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.less']
} )
export class ProjectFormComponent implements OnInit, OnDestroy {
  @Input() projectForm: FormGroup;

  private _subs: Array<Subscription>;
  private _currentUser: UserAggregate;

  constructor (
    private _userService: UserService
  ) {
    this._subs = [];
  }

  ngOnDestroy(): void {
    this._subs.forEach( s => s.unsubscribe() );
  }

  ngOnInit(): void {
    this._subs.push(
      this._userService.userInfos$.pipe( filter( u => u !== undefined ) ).subscribe( u => {
        this._currentUser = u;
      } )
    );
  }

  getFormValue(): FormGroup {
    return this.projectForm;
  }

  shouldShowSpecialProjectBtn(): boolean {
    if ( this._currentUser.isAdmin ) {
      return true;
    }
    return false;
  }
}
