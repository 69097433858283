<ng-container *ngIf="getPost">
    <nz-modal 
        [(nzVisible)]="isVisible" 
        [nzContent]="modalContent"
        [nzFooter]="modalFooter"
        class="post-modal"
        (nzOnCancel)="closeModal()">
        <div *nzModalTitle class="modal-title">
            <span>
                # {{ getPost.postId }}
                <nz-tag *ngIf="getPost.isPrivate" nzColor="error">
                    <i nz-icon nzType="eye-invisible" nzTheme="outline"></i>
                </nz-tag>
            </span>
            <div *ngIf="isAction && !editingPostStatus" [ngSwitch]="getStatusId()" class="action-status">
                <ng-container *ngSwitchCase="actionTypes.PENDING">
                    <nz-tag nzColor="default">
                        {{ getStatusText() }}
                    </nz-tag>
                </ng-container>
                <ng-container *ngSwitchCase="actionTypes.ONGOING">
                    <nz-tag [nzColor]="'gold'">
                        {{ getStatusText() }}
                    </nz-tag>
                </ng-container>
                <ng-container *ngSwitchCase="actionTypes.COMPLETED">
                    <nz-tag [nzColor]="'blue'">
                        {{ getStatusText() }}
                    </nz-tag>
                </ng-container>
                <ng-container *ngSwitchCase="actionTypes.CANCELED">
                    <nz-tag nzColor="error">
                        {{ getStatusText() }}
                    </nz-tag>
                </ng-container>
                <a nz-button nzType="link" *ngIf="!currentUserIsViewer && !editingPostStatus;" (click)="startEditingActionStatus()">
                    <i nz-icon nzType="edit" nzTheme="outline"></i>
                </a>
            </div>
            <div class="action-status select" *ngIf="editingPostStatus">
                <nz-select [(ngModel)]="selectedStatusId">
                    <nz-option *ngFor="let s of allActionStatus" [nzLabel]="s.actionStatusText" [nzValue]="s.actionStatusId"></nz-option>
                </nz-select>
                <button nz-button nzType="primary" (click)="editActionStatus()">
                    <i nz-icon nzType="check" nzTheme="outline"></i>
                </button>
                <a nz-button nzType="link" (click)="editingPostStatus = false">
                    <i nz-icon nzType="close" nzTheme="outline"></i>
                </a>
            </div>
            <button class="archive-btn" *ngIf="!getPost.isArchived && !currentUserIsViewer; else restore" nz-button nzType="primary" (click)="archivePost()">
                <i nz-icon nzType="delete" nzTheme="outline"></i>
                Archiver
            </button>
            <ng-template #restore>
                <button *ngIf="!currentUserIsViewer" nz-button nzType="primary" (click)="restorePost()">
                    <i nz-icon nzType="undo" nzTheme="outline"></i>
                    Restaurer
                </button>
            </ng-template>
        </div>
        <ng-template #modalContent>
            <div class="infos">
                <!-- display tag according to postType -->
                <div [ngSwitch]="getPost.postTypeId">
                    <ng-container *ngSwitchCase="postTypes.INFORMATION">                        
                        <nz-tag [nzColor]="'gold'">
                            <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                            {{ getPost.postType }}
                        </nz-tag>
                    </ng-container>
                    <ng-container *ngSwitchCase="postTypes.DECISION">                        
                        <nz-tag [nzColor]="'volcano'">
                            <i nz-icon nzType="exclamation-circle" nzTheme="outline"></i>
                            {{ getPost.postType }}
                        </nz-tag>
                    </ng-container>
                    <ng-container *ngSwitchCase="postTypes.ACTION">                        
                        <nz-tag [nzColor]="'blue'">
                            <i nz-icon nzType="issues-close" nzTheme="outline"></i>
                            {{ getPost.postType }}
                        </nz-tag>
                    </ng-container>
                    <ng-container *ngIf="getPost.isImportant">
                        <nz-tag nzColor="error">
                            <i nz-icon nzType="warning" nzTheme="outline"></i>
                            Important
                        </nz-tag>
                    </ng-container>
                    <ng-container *ngIf="getPost.isArchived">
                        <nz-tag nzColor="default">
                            <i nz-icon nzType="delete" nzTheme="outline"></i>
                            Archivé
                        </nz-tag>
                    </ng-container>
                </div>
                <div class="owner">
                    Posté par : {{ getPost.ownerFirstName }} {{ getPost.ownerLastName }} ({{ getPost.ownerUserName }}) <br/>
                    Le {{ formatDate( getPost.creationDate ) }}
                </div>
            </div>

            <div class="post-content">
                <div class="content-container" *ngIf="!editingPostContent; else contentEdition">
                    <span *ngIf="!currentUserIsViewer">
                        <!-- Call method that handles data => if its a link then display a href -->
                        <ng-container *ngIf="isValidURL(getPost.title); else normalContent">
                            <a [href]="getPost.title">{{ getPost.title }}</a>
                        </ng-container>
                        <ng-template #normalContent>
                            {{ getPost.title }}
                        </ng-template>
                    </span>
                    <a *ngIf="!editingPostContent" nz-button nzType="link" (click)="startEditingPost()">
                        <i nz-icon nzType="edit" nzTheme="outline"></i>
                    </a>
                </div>
                <ng-template #contentEdition>
                    <input #editInput nz-input [(ngModel)]="newPostContent" (blur)="newPostContent !== getPost.title ?? cancelPostEdition()" />
                    <button *ngIf="!currentUserIsViewer" nz-button nzType="primary" (click)="editPost()" [disabled]="newPostContent !== getPost.title ? false : true">
                        Valider
                    </button>
                    <button *ngIf="!currentUserIsViewer" nz-button nzType="default" (click)="cancelPostEdition()">Annuler</button>
                </ng-template>
            </div>

            <div class="comments">
                <span>Commentaires :</span>
                <nz-textarea-count [nzMaxCharacterCount]="400">
                    <textarea 
                        class="comment-input" 
                        rows="1" 
                        [(ngModel)]="commentContent" 
                        nz-input 
                        placeholder="Ajouter un commentaire" 
                        (focus)="showAddCommentBtn = true"
                        (blur)="commentContent.length === 0 ? showAddCommentBtn = false : showAddCommentBtn = true">
                    </textarea>
                </nz-textarea-count>
                <div class="comment-btn" *ngIf="showAddCommentBtn">
                    <button nz-button nzType="primary" (click)="commentPost()" [disabled]="commentContent.length === 0">Commenter</button>
                </div>

                <nz-list class="comment-list">
                    <nz-list-item *ngFor="let c of getPost.comments;" class="comment">
                        <div class="content">
                            <ng-container *ngIf="!currentComment || currentComment.commentId !== c.commentId; else commentEdition">
                                <span>{{ c.commentValue }}</span>
                                <div class="buttons" *ngIf="currentComment !== c">
                                    <a nz-button nzType="link" (click)="startEditingComment( c )" nz-tooltip nzTooltipTitle="Modifier">
                                        <i nz-icon nzType="edit" nzTheme="outline"></i>
                                    </a>
                                    <a *ngIf="!currentUserIsViewer || c.ownerId === currentUserId" nz-button nzType="link" (click)="deleteComment( c )" nz-tooltip nzTooltipTitle="Supprimer">
                                        <i nz-icon nzType="delete" nzTheme="outline"></i>
                                    </a>
                                </div>
                            </ng-container>
                            <ng-template #commentEdition>
                                <input #editCommentInput nz-input 
                                    [(ngModel)]="newCommentContent" (blur)="newCommentContent !== currentComment.commentValue ?? cancelCommentEdition()" />
                                <button nz-button nzType="primary" (click)="editComment()" 
                                    [disabled]="newCommentContent !== currentComment.commentValue ? false : true">
                                    Valider
                                </button>
                                <button nz-button (click)="cancelCommentEdition()">Annuler</button>
                            </ng-template>
                        </div>
                        <div class="infos">
                            <span>{{ c.ownerFirstName }} {{ c.ownerLastName }} ({{ c.ownerUserName }}) le {{ formatDate( c.creationDate ) }}</span>
                            <span *ngIf="formatDate(c.editionDate) !== '-'">Modifié le : {{ formatDate( c.editionDate ) }}</span>
                        </div>
                    </nz-list-item>
                    <ng-template #commentEdition>
                        <ng-container *ngIf="currentComment">
                            <input #editCommentInput nz-input 
                                [(ngModel)]="newCommentContent" (blur)="newCommentContent !== currentComment.commentValue ?? cancelPostEdition()" />
                            <button nz-button nzType="primary" (click)="editComment()" 
                                [disabled]="newCommentContent !== currentComment.commentValue ? false : true">
                                Valider
                            </button>
                            <button nz-button (click)="cancelCommentEdition()">Annuler</button>
                        </ng-container>
                    </ng-template>
                </nz-list>
            </div>
        </ng-template>
        <ng-template #modalFooter>
            <div class="footer">
                <ng-container *ngIf="formatDate( getPost.lastEditionDate ) !== '-'">
                    <span class="owner">
                        Modifié le {{ formatDate( getPost.lastEditionDate ) }} par {{ getPost.editorFirstName }} {{ getPost.editorLastName }} ({{ getPost.editorUserName }})
                    </span>
                </ng-container>
                <button nz-button nzType="default" (click)="closeModal()">Fermer</button>
            </div>
        </ng-template>
    </nz-modal>
</ng-container>