export var AG_GRID_LOCALE_FR = {
    // Set Filter
    selectAll: '(Tout sélectionner)',
    selectAllSearchResults: '(Sélectionner tous les résultats)',
    searchOoo: 'Rechercher...',
    blanks: '(Vide)',
    noMatches: 'Pas de résultats',

    // Number Filter & Text Filter
    filterOoo: 'Filtrer...',
    equals: 'Égal à',
    notEqual: 'Différent de',
    empty: 'Sélectionner...',

    // Number Filter
    lessThan: 'Inférieur à',
    greaterThan: 'Supérieur à',
    lessThanOrEqual: 'Inférieur ou égal à',
    greaterThanOrEqual: 'Supérieur ou égal à',
    inRange: 'Dans l\'intervalle',
    inRangeStart: 'min.',
    inRangeEnd: 'max.',

    // Text Filter
    contains: 'Contient',
    notContains: 'Ne contient pas',
    startsWith: 'Commence par',
    endsWith: 'Finit par',

    // Date Filter
    dateFormatOoo: 'dd/mm/yyyy',

    // Filter Conditions
    andCondition: 'ET',
    orCondition: 'OU',

    // Filter Buttons
    applyFilter: 'Appliquer',
    resetFilter: 'Réinitialiser',
    clearFilter: 'Vider',
    cancelFilter: 'Annuler',

    // Filter Titles
    textFilter: 'Filtre Texte',
    numberFilter: 'Filtre Nombre',
    dateFilter: 'Filtre Date',
    setFilter: 'Filtre Ensemble',

    // Side Bar
    columns: 'Colonnes',
    filters: 'Filtres',

    // columns tool panel
    pivotMode: 'Mode Tableau Croisé Dynamique',
    groups: 'Groupes de Lignes',
    rowGroupColumnsEmptyMessage: 'Cliquez-glissez ici pour définir des groupes de lignes',
    values: 'Valeurs',
    valueColumnsEmptyMessage: 'Cliquez-glissez ici pour agréger',
    pivots: 'Libellés Colonnes',
    pivotColumnsEmptyMessage: 'Cliquez-glissez ici pour définir des libellés de colonnes',

    // Header of the Default Group Column
    group: 'Groupe',

    // Other
    loadingOoo: 'Chargement...',
    noRowsToShow: 'Pas de ligne à afficher',
    enabled: 'Activé',

    // Menu
    pinColumn: 'Épingler la Colonne',
    pinLeft: 'Épingler à gauche',
    pinRight: 'Épingler à droite',
    noPin: 'Pas d\'épingle',
    valueAggregation: 'Agrégat des Valeurs',
    autosizeThiscolumn: 'Redimensionner cette colonne automatiquement',
    autosizeAllColumns: 'Redimensionner toutes les colonnes automatiquement',
    groupBy: 'Regrouper par',
    ungroupBy: 'Dégrouper par',
    resetColumns: 'Réinitialiser les colonnes',
    expandAll: 'Développer tout',
    collapseAll: 'Refermer tout',
    copy: 'Copier',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Copier avec les en-têtes',
    paste: 'Coller',
    ctrlV: 'Ctrl+V',
    export: 'Exporter',
    csvExport: 'Exporter en CSV',
    excelExport: 'Exporter en Excel (.xlsx)',
    excelXmlExport: 'Exporter en Excel (.xml)',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Somme',
    min: 'Min',
    max: 'Max',
    none: 'Rien',
    count: 'Décompte',
    avg: 'Moyenne',
    filteredRows: 'Filtré',
    selectedRows: 'Sélectionné',
    totalRows: 'Lignes Totales',
    totalAndFilteredRows: 'Lignes',
    more: 'Plus',
    to: 'à',
    of: 'sur',
    page: 'Page',
    nextPage: 'Page Suivante',
    lastPage: 'Dernière Page',
    firstPage: 'Première Page',
    previousPage: 'Page Précédente',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Mode Graphique Croisé Dynamique et Tableau Croisé Dynamique',
    pivotChart: 'Graphique Croisé Dynamique',
    chartRange: 'Plage du Graphique',

    columnChart: 'Colonne',
    groupedColumn: 'Regroupée',
    stackedColumn: 'Empilée',
    normalizedColumn: '100% Empilée',

    barChart: 'Barre',
    groupedBar: 'Regroupée',
    stackedBar: 'Empilée',
    normalizedBar: '100% Empilée',

    pieChart: 'Diagramme circulaire',
    pie: 'Camembert',
    doughnut: 'Graphique en anneau',

    line: 'Ligne',

    xyChart: 'X Y (Dispersion)',
    scatter: 'Dispersion',
    bubble: 'Bulle',

    areaChart: 'Surface',
    area: 'Surface',
    stackedArea: 'Empilée',
    normalizedArea: '100% Empilée',

    histogramChart: 'Histogramme',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Settings',
    data: 'Data',
    format: 'Format',
    categories: 'Categories',
    defaultCategory: '(None)',
    series: 'Series',
    xyValues: 'X Y Values',
    paired: 'Paired Mode',
    axis: 'Axis',
    navigator: 'Navigator',
    color: 'Color',
    thickness: 'Thickness',
    xType: 'X Type',
    automatic: 'Automatic',
    category: 'Category',
    number: 'Number',
    time: 'Time',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    padding: 'Padding',
    spacing: 'Spacing',
    chart: 'Chart',
    title: 'Title',
    titlePlaceholder: 'Chart title - double click to edit',
    background: 'Background',
    font: 'Font',
    top: 'Top',
    right: 'Right',
    bottom: 'Bottom',
    left: 'Left',
    labels: 'Labels',
    size: 'Size',
    minSize: 'Minimum Size',
    maxSize: 'Maximum Size',
    legend: 'Legend',
    position: 'Position',
    markerSize: 'Marker Size',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Item Spacing',
    itemPaddingX: 'Item Padding X',
    itemPaddingY: 'Item Padding Y',
    layoutHorizontalSpacing: 'Horizontal Spacing',
    layoutVerticalSpacing: 'Vertical Spacing',
    strokeWidth: 'Stroke Width',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Shadow',
    blur: 'Blur',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Line Width',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Italic',
    boldItalic: 'Bold Italic',
    predefined: 'Predefined',
    fillOpacity: 'Fill Opacity',
    strokeOpacity: 'Line Opacity',
    histogramBinCount: 'Bin count',
    columnGroup: 'Column',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Line',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Histogram',
    groupedColumnTooltip: 'Grouped',
    stackedColumnTooltip: 'Stacked',
    normalizedColumnTooltip: '100% Stacked',
    groupedBarTooltip: 'Grouped',
    stackedBarTooltip: 'Stacked',
    normalizedBarTooltip: '100% Stacked',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Line',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Stacked',
    normalizedAreaTooltip: '100% Stacked',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Histogram',
    noDataToChart: 'No data available to be charted.',
    pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.'
}
