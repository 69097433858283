import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IServerSideGetRowsRequest } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { GroupInfos, UserAggregate } from 'src/app/admin/shared/models/user.model';
import { DbViewResult } from 'src/app/shared/models/ag-grid.model';
import { CONFIG } from '../config/injectionConfig';
import { IWebSettings } from '../config/websettings.model';

@Injectable( {
  providedIn: 'root'
} )
export class AdminApiService {

  private _baseUrl: string;

  constructor (
    private _httpClient: HttpClient,
    @Inject( CONFIG ) config: IWebSettings
  ) {
    this._baseUrl = `${config.apiRoot}/admin`;
  }

  public getAllUsers( rq: IServerSideGetRowsRequest ): Observable<DbViewResult<UserAggregate>> {
    const params = new HttpParams().set( 'req', JSON.stringify( rq ) );
    return this._httpClient.get<DbViewResult<UserAggregate>>( `${this._baseUrl}/users`, { params: params } );
  }

  public getCurrentUserInfos(): Observable<UserAggregate> {
    return this._httpClient.get<UserAggregate>( `${this._baseUrl}/currentUser` );
  }

  public getPlatformAndSignatureGroups(): Observable<Array<GroupInfos>> {
    return this._httpClient.get<Array<GroupInfos>>( `${this._baseUrl}/groups` );
  }
}
