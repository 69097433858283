<div class="topbar-container">
    <div class="logo" (click)="goToHome()">
        <img src="../../../../assets/Logo-code.png" />
    </div>
    <div class="profile">
        <nz-avatar
            [nzSize]="32"
            nz-dropdown
            [nzText]="userInitials"
            [nzDropdownMenu]="profileMenu">
        </nz-avatar>
        <nz-dropdown-menu #profileMenu="nzDropdownMenu">
        <ul nz-menu>
            <li *ngIf="shoudShowPrivateMode()" nz-menu-item class="private-mode-btn">
                <nz-switch [(ngModel)]="privateMode" (ngModelChange)="setPrivateMode($event)"></nz-switch>
                Mode privé
            </li>
            <li nz-menu-item routerLink="/profile">
                <i nz-icon nzType="user" nzTheme="outline"></i>
                <span style="margin-left: 5px;">Profile</span>
            </li>
            <li nz-menu-item (click)="logout()">
                <i nz-icon nzType="logout" nzTheme="outline"></i>
                <span style="margin-left: 5px;">Logout</span>
            </li>
        </ul>
        </nz-dropdown-menu>
    </div>
</div>