import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { AuthService } from '@signature/webfrontauth';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { ProjectApiService } from 'src/app/core/api/project-api.service';
import { CrsService } from 'src/app/core/crs/crs.service';
import { DefaultCommandResponse } from 'src/app/core/crs/response.model';
import { dateToLocalFR } from 'src/app/shared/date-helper';
import { UserService } from 'src/app/shared/services/user.service';
import { ArchivePostCommand } from '../../shared/commands/post/archive-post.command';
import { CreateCommentCommand } from '../../shared/commands/comment/create-comment.command';
import { EditPostCommand } from '../../shared/commands/post/edit-post.command';
import { RestorePostCommand } from '../../shared/commands/post/restore-post.command';
import { ACTION_STATUS, POST_TYPES } from '../../shared/models/posts.enum';
import { Action, ActionStatus, Comment, Post } from '../../shared/models/project.model';
import { EditCommentCommand } from '../../shared/commands/comment/edit-comment.command';
import { DeleteCommentCommand } from '../../shared/commands/comment/delete-comment.command';
import { ActionStatusSetCommand } from '../../shared/commands/post/action-status-set.command';

@Component( {
  selector: 'app-post-modal',
  templateUrl: './post-modal.component.html',
  styleUrls: ['./post-modal.component.less']
} )
export class PostModalComponent implements OnInit, OnDestroy {
  @Input() isVisible: boolean = false;

  get getPost(): Post | Action {
    return this._post;
  }
  @Input() set post( p: Post | Action ) {
    if ( typeof p !== 'undefined' ) {
      if ( 'actionStatusId' in p ) {
        this.isAction = true;
      }
      this._post = { ...p };
      this.refreshData();
    }
  };

  @Output() modalClosed = new EventEmitter<void>();

  @ViewChild( 'editInput' ) editInput: HTMLInputElement;
  @ViewChild( 'editCommentInput' ) editCommentInput: HTMLInputElement;

  private _subscriptions: Array<Subscription>;
  private _post: Post | Action;
  public isAction: boolean;
  public postTypes = POST_TYPES;
  public commentContent: string;
  public showAddCommentBtn: boolean;
  public editingPostContent: boolean;
  public newPostContent: string;
  public currentUserIsViewer: boolean;
  public currentUserId: number;
  public editingComment: boolean;
  public newCommentContent: string;
  public actionTypes = ACTION_STATUS;
  public currentComment?: Comment;
  public editingPostStatus: boolean;
  public allActionStatus: Array<ActionStatus>;
  public selectedStatusId: number;

  constructor (
    private _authService: AuthService,
    private _crsService: CrsService,
    private _nzMessageService: NzMessageService,
    private _userService: UserService,
    private _projectApiService: ProjectApiService
  ) {
    this.commentContent = '';
    this.newPostContent = '';
    this.showAddCommentBtn = false;
    this.editingPostContent = false;

    this._subscriptions = new Array<Subscription>();
    this.currentUserIsViewer = true;
    this.editingComment = false;
    this.newCommentContent = '';
    this.isAction = false;
    this.editingPostStatus = false;
  }

  ngOnInit(): void {
    this.getActionStatus();

    if ( this._post ) {
      this.newPostContent = this._post.title;
    }

    this._subscriptions.push(
      this._userService.userInfos$.pipe( filter( u => u !== undefined ) ).subscribe( u => {
        if ( u.isAdmin ||
          u.groups.filter( g => g.zoneId === this._post.projectId && !g.groupName.includes( 'Viewers' ) ).length > 0 ||
          u.groups.filter( g => g.groupName === 'Signature.Editors' || g.groupName === 'Signature.Admins' ).length > 0 ) {
          this.currentUserIsViewer = false;
        }
      } )
    );

    this.currentUserId = this._authService.authenticationInfo.user.userId;
  }

  getActionStatus(): void {
    this._projectApiService.getAllActionStatus().pipe( first() ).subscribe( as => {
      this.allActionStatus = [...as];
      if ( this.isAction ) {
        this.selectedStatusId = as.find( s => s.actionStatusId === ( this.getPost as Action ).actionStatusId ).actionStatusId;
      }
    } );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach( s => s.unsubscribe() );
  }

  closeModal(): void {
    this.cancelPostEdition();
    this.modalClosed.next();
  }

  formatDate( date: Date ): string {
    return dateToLocalFR( date.toString() );
  }

  commentPost(): void {
    const successMsg = 'Le commentaire a été ajouté avec succès.';
    const errorMsg = 'Une erreur est survenue lors de la création du commentaire.';

    const command = new CreateCommentCommand(
      this._authService.authenticationInfo.user.userId,
      this._post.postId,
      this.commentContent
    );
    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
        this.commentContent = '';
        this.showAddCommentBtn = false;
      } else {
        this._nzMessageService.error( errorMsg );
      }

      this.refreshData();
    } );
  }

  refreshData(): void {
    this._projectApiService
      .getPost( this._post.postId )
      .pipe( first() )
      .subscribe( p => this._post = { ...p } );
  }

  startEditingPost(): void {
    this.editingPostContent = true;
    this.newPostContent = this._post.title;
    this.editInput.focus();
  }

  cancelPostEdition(): void {
    this.editingPostContent = false;
    this.newPostContent = this._post.title;
  }

  editPost(): void {
    const successMsg = 'Le contenu du post a été modifié avec succès.';
    const errorMsg = 'Une erreur est survenue lors de la modification du post.';

    const command = new EditPostCommand(
      this._authService.authenticationInfo.user.userId,
      this._post.postId,
      this.newPostContent,
      this._post.isImportant
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.refreshData();
      this.cancelPostEdition();
    } );
  }

  archivePost(): void {
    const successMsg = 'Le post a été archivé avec succès.';
    const errorMsg = 'Une erreur est survenue lors de l\'archivage du post.';

    const command = new ArchivePostCommand(
      this._authService.authenticationInfo.user.userId,
      this._post.postId
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.refreshData();
    } );
  }

  restorePost(): void {
    const successMsg = 'Le post a été restauré avec succès.';
    const errorMsg = 'Une erreur est survenue lors de la restauration du post.';

    const command = new RestorePostCommand(
      this._authService.authenticationInfo.user.userId,
      this._post.postId
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.refreshData();
    } );
  }

  startEditingComment( c: Comment ): void {
    this.currentComment = { ...c };
    this.editingComment = true;
    this.newCommentContent = c.commentValue;
    // this.editCommentInput.focus();
  }

  cancelCommentEdition(): void {
    this.editingComment = false;
    this.newCommentContent = this.currentComment.commentValue;
    this.currentComment = undefined;
  }

  editComment(): void {
    const successMsg = 'Le contenu du commentaire a été modifié avec succès.';
    const errorMsg = 'Une erreur est survenue lors de la modification du commentaire.';

    const command = new EditCommentCommand(
      this._authService.authenticationInfo.user.userId,
      this.currentComment.commentId,
      this.newCommentContent
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.refreshData();
      this.cancelCommentEdition();
    } );
  }

  deleteComment( c ): void {
    const successMsg = 'Le commentaire a été supprimé avec succès.';
    const errorMsg = 'Une erreur est survenue lors de la suppression du commentaire.';

    const command = new DeleteCommentCommand(
      this._authService.authenticationInfo.user.userId,
      c.commentId
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.refreshData();
      this.cancelCommentEdition();
    } );
  }

  getStatusId(): number {
    if ( this.isAction ) {
      if ( ( this.getPost as Action ).actionStatusId ) {
        return ( this.getPost as Action ).actionStatusId;
      }
    }

    return 1;
  }

  getStatusText(): string {
    if ( this.isAction ) {
      return ( this.getPost as Action ).actionStatusText;
    }

    return 'En attente';
  }

  startEditingActionStatus(): void {
    this.editingPostStatus = true;
  }

  editActionStatus(): void {
    if ( this.isAction ) {
      const command = new ActionStatusSetCommand(
        this._authService.authenticationInfo.user.userId,
        this.getPost.postId,
        this.selectedStatusId
      );

      this._crsService.send<DefaultCommandResponse>( command ).then( res => {
        if ( res.success ) {
          this.editingPostStatus = false;
          this.refreshData();
        }
      } );
    }
  }

  isValidURL( string: string ): boolean {
    var res = string.match( /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g );
    return ( res !== null )
  }
}
