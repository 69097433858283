<div class="form-container">
    <ng-container *ngIf="projectForm">
        <form nz-form [formGroup]="projectForm">
            <nz-form-item>
                <nz-form-control>
                    <nz-form-label [nzRequired]="true">Nom</nz-form-label>
                    <nz-input-group>
                        <input formControlName="projectName" nz-input />
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <nz-form-label>Description</nz-form-label>
                    <nz-input-group>
                        <textarea rows="4" formControlName="description" nz-input></textarea>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <ng-container *ngIf="shouldShowSpecialProjectBtn()">
                <nz-form-item>
                    <nz-form-control>
                        <nz-form-label>Projet privé</nz-form-label>  
                        <nz-switch formControlName="isPrivate"></nz-switch>
                    </nz-form-control>
                </nz-form-item>
            </ng-container>
        </form>
    </ng-container>
</div>