<div class="actions">
    <div class="left-buttons">
        <ng-container *ngFor="let action of actions.left">
            <div *ngIf="shouldShow( action )">
                <ng-container *ngIf="action.isDanger; else normal">
                    <button nz-button nzType="primary" nzSize="large" nzDanger (click)="buttonClicked(action)">
                        <i nz-icon [nzType]="action.iconType" nzTheme="outline"></i>
                        {{ action.displayName }}
                    </button>
                </ng-container>
                <ng-template #normal>
                    <button nz-button nzType="primary" nzSize="large" (click)="buttonClicked(action)">
                        <i nz-icon [nzType]="action.iconType" nzTheme="outline"></i>
                        {{ action.displayName }}
                    </button>
                </ng-template>
            </div>
        </ng-container>
    </div>
    <div class="right-buttons">
        <ng-container *ngFor="let action of actions.right">
            <div *ngIf="shouldShow( action )">
                <ng-container *ngIf="action.isDanger; else normal">
                    <button nz-button nzType="primary" nzSize="large" nzDanger (click)="buttonClicked(action)">
                        <i nz-icon [nzType]="action.iconType" nzTheme="outline"></i>
                        {{ action.displayName }}
                    </button>
                </ng-container>
                <ng-template #normal>
                    <button nz-button nzType="primary" nzSize="large" (click)="buttonClicked(action)">
                        <i nz-icon [nzType]="action.iconType" nzTheme="outline"></i>
                        {{ action.displayName }}
                    </button>
                </ng-template>
            </div>
        </ng-container>
    </div>
</div>