import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationRoutingModule } from './authentication-routing.module';

import { AuthenticationComponent } from './page/authentication.component';
import { LoginComponent } from './components/login/login.component';
import { IconsProviderModule } from '../icons-provider.module';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageModule } from 'ng-zorro-antd/message';

@NgModule( {
  declarations: [
    AuthenticationComponent,
    LoginComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    NzButtonModule,
    NzCheckboxModule,
    NzFormModule,
    IconsProviderModule,
    NzInputModule,
    NzMessageModule,

    AuthenticationRoutingModule
  ]
} )
export class AuthenticationModule { }
