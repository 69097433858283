import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@signature/webfrontauth';
import { IServerSideDatasource } from 'ag-grid-community';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { UserAggregate } from 'src/app/admin/shared/models/user.model';
import { ProjectApiService } from 'src/app/core/api/project-api.service';
import { CrsService } from 'src/app/core/crs/crs.service';
import { DefaultCommandResponse } from 'src/app/core/crs/response.model';
import { GenericModalComponent } from 'src/app/shared/components/generic-modal/generic-modal.component';
import { ValidatedForm } from 'src/app/shared/models/form-validation.model';
import { TableAction } from 'src/app/shared/models/table-action.model';
import { TableHelperService } from 'src/app/shared/services/table-helper.service';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ProjectFormComponent } from '../../components/project-form/project-form.component';
import { ArchiveProjectCommand } from '../../shared/commands/project/archive-project.command';
import { CreateProjectCommand } from '../../shared/commands/project/create-project.command';
import { DeleteProjectCommand } from '../../shared/commands/project/delete-project.command';
import { EditProjectCommand } from '../../shared/commands/project/edit-project.command';
import { RestoreProjectCommand } from '../../shared/commands/project/restore-project.command';
import { PROJECT_STATUS } from '../../shared/models/project-status.model';
import { Project } from '../../shared/models/project.model';

@Component( {
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.less']
} )
export class ProjectsComponent implements OnInit, OnDestroy {
  @ViewChild( 'modalComponent' ) modalComponent: GenericModalComponent;

  private _currentUser: UserAggregate;
  private _subs: Array<Subscription>;
  public actions: { left: Array<TableAction<Project>>, mid: Array<TableAction<Project>>, right: Array<TableAction<Project>> }
  public selectedProjects: Array<Project>;
  public serverSideDatasource?: IServerSideDatasource;
  public pageSize: number;
  public projects: Array<Project>;
  public projectStatus = PROJECT_STATUS;
  public currentUserIsViewer: boolean;
  public selectedProject?: Project;

  constructor (
    private _formBuilder: FormBuilder,
    private _projectApiService: ProjectApiService,
    private _authService: AuthService,
    private _nzMessageService: NzMessageService,
    private _crsService: CrsService,
    private _userService: UserService
  ) {
    this.actions = { left: new Array<TableAction<Project>>(), mid: new Array<TableAction<Project>>(), right: new Array<TableAction<Project>>() };
    this.selectedProjects = new Array<Project>();
    this.pageSize = 10;
    this.projects = new Array<Project>();
    this._subs = new Array<Subscription>();
    this.currentUserIsViewer = true;
  }

  ngOnDestroy(): void {
    this._subs.forEach( s => s.unsubscribe() );
  }

  ngOnInit(): void {
    this.initActions();
    // this.setDataSource();
    this.getProjects();

    this._subs.push(
      this._userService.userInfos$.pipe( filter( u => u !== undefined ) ).subscribe( u => {
        this._currentUser = u;

        if ( this._currentUser.isAdmin || this._currentUser.groups.filter( g => g.groupName.includes( 'Signature.' ) ).length > 0 ) {
          this.currentUserIsViewer = false;
        }
      } )
    );
  }

  getProjects(): void {
    this._projectApiService.getProjects().pipe( first() ).subscribe( p => this.projects = [...p] );
  }

  initActions(): void {
    const createProject = {
      displayName: 'Ajouter un projet',
      name: 'create',
      iconType: 'plus',
      isDanger: false,
      shouldBeDisplayed: () => {
        const user = this._userService.getUser();
        if ( user && ( user.isAdmin || user.groups.filter( g => g.groupName === 'Signature.Editors' || g.groupName === 'Signature.Admins' ).length > 0 ) ) {
          return true;
        }
        return false;
      },
      execute: () => {
        this.openCreateProjectModal();
      }
    };

    this.actions.left = [createProject];
  }

  createProjectFormGroup(): FormGroup {
    return this._formBuilder.group( {
      projectName: ['', Validators.required],
      description: [''],
      isPrivate: [false]
    } );
  }

  openCreateProjectModal(): void {
    const title = `Nouveau project`;
    const form: FormGroup = this.createProjectFormGroup();
    const componentParams = { projectForm: form };
    this.modalComponent.openFormModal( title, ProjectFormComponent, componentParams, 'createProject' );
  }

  openEditProjectModal( project: Project ): void {
    this.selectedProject = project;
    const title = `Modification du projet ${project.projectName}`;
    const form: FormGroup = this.createEditProjectFormGroup( project );
    const componentParams = { projectForm: form };
    this.modalComponent.openFormModal( title, ProjectFormComponent, componentParams, 'editProject' );
  }

  createEditProjectFormGroup( project: Project ): FormGroup {
    return this._formBuilder.group( {
      projectName: [project.projectName, Validators.required],
      description: [project.description],
      isPrivate: [project.isPrivate]
    } );
  }

  modalFormValidated( result: ValidatedForm ): void {
    let successMsg = `La création s'est déroulée avec succès.`;
    let errorMsg = `Une erreur est survenue lors de la création.`;

    if ( result.action === 'editProject' ) {
      successMsg = 'La modification a bien été effectuée.';
      errorMsg = 'Une erreur est survenue lors de l\'édition';
    }

    const command = this.createCrsCommand( result.form, result.action );
    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.selectedProject = undefined;

      this.getProjects();
    } );
  }

  createCrsCommand( form: FormGroup, actionName: string ) {
    let command = null;
    switch ( actionName ) {
      case 'createProject':
        command = new CreateProjectCommand(
          this._authService.authenticationInfo.user.userId,
          form.get( 'projectName' ).value,
          form.get( 'description' ).value,
          form.get( 'isPrivate' ).value
        );
        break;
      case 'editProject':
        command = new EditProjectCommand(
          this._authService.authenticationInfo.user.userId,
          this.selectedProject.projectId,
          form.get( 'projectName' ).value,
          form.get( 'description' ).value,
          form.get( 'isPrivate' ).value
        );
        break;

      default:
        break;
    }
    return command;
  }

  getProjectsToDisplay(): Array<Project> {
    if ( this._currentUser ) {
      if ( this._currentUser.isAdmin ) {
        return this.projects;
      } else if ( this._currentUser.groups.filter( g => g.groupName.includes( 'Signature.' ) ).length > 0 ) {
        return this.projects.filter( p => !p.isPrivate || this._currentUser.groups.find( g => g.groupName.includes( p.projectName ) ) );
      }

      return this.projects.filter( p => this._currentUser.groups.find( g => g.zoneId === p.projectId ) !== undefined );
    }

    return [];
  }

  displayConfirmModal( project: Project ): void {
    const title = `Êtes-vous sûr(e) de vouloir supprimer le projet : ${project.projectName} ?`;
    const successMsg = `Le projet ${project.projectName} a été supprimé.`;
    const errorMsg = `Une erreur est survenue lors de la suppression du projet.`;

    const command = new DeleteProjectCommand( this._authService.authenticationInfo.user.userId, project.projectId );

    this.modalComponent.openConfirmModal( title, successMsg, errorMsg, command );
  }

  archive( projectId: number ): void {
    const successMsg = `L'archivage s'est déroulée avec succès.`;
    const errorMsg = `Une erreur est survenue lors de l'archivage.`;

    const command = new ArchiveProjectCommand(
      this._authService.authenticationInfo.user.userId,
      projectId
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }

      this.getProjects();
    } );
  }

  restoreProject( projectId: number ): void {
    const successMsg = `La restauration s'est déroulée avec succès.`;
    const errorMsg = `Une erreur est survenue lors de la restauration.`;

    const command = new RestoreProjectCommand(
      this._authService.authenticationInfo.user.userId,
      projectId
    );

    this._crsService.send<DefaultCommandResponse>( command ).then( ( res: DefaultCommandResponse ) => {
      if ( res.success ) {
        this._nzMessageService.success( successMsg );
      } else {
        this._nzMessageService.error( errorMsg );
      }
      this.getProjects();
    } );
  }
}
