import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';

@Component( {
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.less']
} )
export class HomePageComponent implements OnInit {

  constructor ( private _userService: UserService ) {
  }

  ngOnInit(): void {
    this._userService.setUserContext();
  }

  setIsCollapse() {
  }
}
