import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, ICellRendererParams } from 'ag-grid-community';
import { PROJECT_STATUS } from '../../shared/models/project-status.model';
import { Project } from '../../shared/models/project.model';

@Component( {
  selector: 'app-tag-renderer',
  templateUrl: './tag-renderer.component.html',
  styleUrls: ['./tag-renderer.component.less']
} )
export class TagRendererComponent implements AgRendererComponent {

  private _params: ICellRendererParams;
  public row: Project;
  public projectStatus = PROJECT_STATUS;

  constructor () { }

  refresh( params: ICellRendererParams ): boolean {
    return true;
  }
  agInit( params: ICellRendererParams ): void {
    this._params = params;
    this.row = params.data;
  }

}
