import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReverseAuthGuardService } from '../core/guards/auth-guard.service';
import { AuthenticationComponent } from './page/authentication.component';

const routes: Routes = [
  {
    path: 'login', component: AuthenticationComponent, canActivate: [ReverseAuthGuardService]
  }
];

@NgModule( {
  imports: [RouterModule.forChild( routes )],
  exports: [RouterModule]
} )
export class AuthenticationRoutingModule { }
