<div [ngSwitch]="row.projectStatusId">
    <ng-container *ngSwitchCase="projectStatus.PENDING">
        <nz-tag [nzColor]="'red'">{{ row.projectStatus }}</nz-tag>
    </ng-container>
    <ng-container *ngSwitchCase="projectStatus.IN_PROGRESS">
        <nz-tag [nzColor]="'gold'">{{ row.projectStatus }}</nz-tag>
    </ng-container>
    <ng-container *ngSwitchCase="projectStatus.COMPLETED">
        <nz-tag [nzColor]="'#87d068'">{{ row.projectStatus }}</nz-tag>
    </ng-container>
    <ng-container *ngSwitchCase="projectStatus.ARCHIVED">
        <nz-tag [nzColor]="'blue'">{{ row.projectStatus }}</nz-tag>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <nz-tag [nzColor]="'blue'">Test</nz-tag>
    </ng-container>
</div>