import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';

import { ActionBarComponent } from './components/action-bar/action-bar.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { GenericTableComponent } from './components/generic-table/generic-table.component';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { IconsProviderModule } from '../icons-provider.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';

@NgModule( {
  declarations: [
    GenericTableComponent,
    ActionBarComponent,
    GenericModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule,
    NzButtonModule,
    NzFormModule,
    NzModalModule,
    IconsProviderModule,
  ],
  exports: [
    GenericTableComponent,
    ActionBarComponent,
    GenericModalComponent
  ]
} )
export class SharedModule { }
