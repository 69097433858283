<div class="projects-container">
    <div class="actions-container">
        <app-action-bar
            [actions]="actions"
            [selectedItems]="selectedProjects"
        ></app-action-bar>
    </div>
    
    <div class="cards-container">
        <ng-container *ngFor="let p of getProjectsToDisplay()">
            <nz-card class="card" [nzTitle]="cardTitle" [nzExtra]="extraTemplate" [nzActions]="[editProject, archiveProject, deleteProject]">
                <ng-template #cardTitle>
                    <div class="card-title">
                        <span>{{ p.projectName }}</span>
                        <ng-container *ngIf="p.isPrivate">
                            <nz-tag nzColor="error">
                                Privé
                            </nz-tag>
                        </ng-container>
                        <div [ngSwitch]="p.projectStatusId">
                            <ng-container *ngSwitchCase="projectStatus.PENDING">
                                <nz-tag [nzColor]="'default'">{{ p.projectStatus }}</nz-tag>
                            </ng-container>
                            <ng-container *ngSwitchCase="projectStatus.IN_PROGRESS">
                                <nz-tag [nzColor]="'gold'">{{ p.projectStatus }}</nz-tag>
                            </ng-container>
                            <ng-container *ngSwitchCase="projectStatus.COMPLETED">
                                <nz-tag [nzColor]="'#87d068'">{{ p.projectStatus }}</nz-tag>
                            </ng-container>
                        </div>
                    </div>
                </ng-template>
                <div class="content">
                    <p class="description">{{p.description}}</p>
                </div>
            </nz-card>
            <ng-template #extraTemplate>
                <div class="extra">
                    <ng-container *ngIf="p.isArchived">
                        <nz-tag [nzColor]="'red'">
                            Archivé
                        </nz-tag>
                    </ng-container>
                    <a [routerLink]="'/projects/' + p.projectId">
                        Détails
                        <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
                    </a>
                </div>
            </ng-template>
            <ng-template #editProject>                    
                <ng-container *ngIf="!currentUserIsViewer">
                    <button
                        nz-button 
                        nzType="primary"
                        nzTooltipPlacement="top" 
                        nz-tooltip
                        nzTooltipTitle="Modifier"
                        (click)="openEditProjectModal( p )">
                        <i nz-icon nzType="edit" nzTheme="outline"></i>
                    </button>
                </ng-container>
            </ng-template>
            <ng-template #archiveProject>                    
                <ng-container *ngIf="!currentUserIsViewer">
                    <button 
                        *ngIf="!p.isArchived; else restore" 
                        nz-button 
                        nzType="primary"
                        nzTooltipPlacement="top" 
                        nz-tooltip
                        nzTooltipTitle="Archiver"
                        (click)="archive( p.projectId )">
                        <i nz-icon nzType="delete" nzTheme="outline"></i>                        
                    </button>
                </ng-container>
            </ng-template>
            <ng-template #restore>
                <button 
                    nz-button 
                    nzType="primary"
                    nzTooltipPlacement="top" 
                    nz-tooltip
                    nzTooltipTitle="Restaurer"
                    (click)="restoreProject( p.projectId )">
                    <i nz-icon nzType="undo" nzTheme="outline"></i>                        
                </button>
            </ng-template>
            <ng-template #deleteProject>
                <button 
                    *ngIf="!currentUserIsViewer" 
                    nzDanger
                    nz-button 
                    nzType="primary" 
                    (click)="displayConfirmModal( p )"
                    nzTooltipPlacement="top" 
                    nz-tooltip
                    nzTooltipTitle="Supprimer">
                    <i nz-icon nzType="close" nzTheme="outline"></i>
                </button>
            </ng-template>
        </ng-container>
    </div>
</div>

<app-generic-modal 
    #modalComponent
    (actionExecuted)="getProjects()"
    (formValidated)="modalFormValidated($event)"
></app-generic-modal>