import { Command } from "@signature/crs-client";

@Command( 'EditProjectStatus' )
export class EditProjectStatusCommand {
    constructor (
        public actorId: number,
        public projectId: number,
        public projectStatusId: number
    ) {
    }
}