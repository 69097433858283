<div class="generic-table-component">
    <ag-grid-angular 
        #agGrid 
        style="height: 90%; width: 100%;"
        class="ag-theme-alpine"
        [rowHeight]="40"
        [paginationPageSize]="pageSize"
        [serverSideStoreType]="storeType"
        [rowModelType]="'serverSide'"
        [columnDefs]="columns"
        [rowData]="rowData"
        [localeText]="localeText"
        (gridReady)="onGridReady($event)">
    </ag-grid-angular>
</div>