import { Command } from "@signature/crs-client";
import { MemberEmail } from "../../models/project.model";

@Command( 'AddRecipientToMailingList' )
export class AddRecipientToMailingListCommand {
    constructor (
        public actorId: number,
        public mailingListId: number,
        public users: Array<MemberEmail>,
        public email: string,
        public firstName: string,
        public lastName: string
    ) {
    }
}