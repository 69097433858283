import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AngularEditorComponent, AngularEditorConfig } from '@kolkov/angular-editor';
import { first } from 'rxjs/operators';
import { ProjectApiService } from 'src/app/core/api/project-api.service';
import { MailTemplate, MemberEmail } from '../../shared/models/project.model';

@Component( {
  selector: 'app-send-mail-form',
  templateUrl: './send-mail-form.component.html',
  styleUrls: ['./send-mail-form.component.less']
} )
export class SendMailFormComponent implements OnInit {
  @ViewChild( 'editor' ) angularEditor: AngularEditorComponent;
  @Input() projectId: number;
  @Input() formGroup: FormGroup;

  public editorConfig: AngularEditorConfig;
  public templates: Array<MailTemplate>;
  public selectedTemplate: MailTemplate;
  public membersEmail: Array<MemberEmail>;

  constructor (
    private _projectApiService: ProjectApiService
  ) {
    this.editorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: '470px',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: '',
      defaultParagraphSeparator: '',
      defaultFontName: 'Calibri',
      defaultFontSize: '',
      sanitize: true,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [
        ['heading', 'fontName'],
        ['insertImage', 'insertVideo']
      ]
    };

    this.templates = [];
    this.membersEmail = [];
  }

  ngOnInit(): void {
    this.getTemplates();
    this.getMemberEmails();
  }

  getMemberEmails(): void {
    this._projectApiService.getMembersEmail( this.projectId ).pipe( first() ).subscribe( emails => {
      this.membersEmail = [...emails];
    } );
  }

  getTemplates(): void {
    this._projectApiService.getAllMailTemplates().pipe( first() ).subscribe( templates => {
      const emptyTemplate = { templateResId: 0, templateName: 'Modèle - Vide', content: '' };
      templates.push( emptyTemplate );
      this.templates = [...templates];
      this.selectedTemplate = emptyTemplate;
    } );
  }

  insert( str, index, value ) {
    return str.substr( 0, index ) + value + str.substr( index );
  }

  getFormValue(): FormGroup {
    return this.formGroup;
  }

  templateChanged( templateResId: number ): void {
    this.angularEditor.placeholder = '';
    this.angularEditor.refreshView( this.templates.find( t => t.templateResId === templateResId ).content );
    this.formGroup.patchValue(
      {
        mailContent: this.templates.find( t => t.templateResId === templateResId ).content
      }
    );
  }

  getMemberEmailDisplay( m: MemberEmail ): string {
    return `${m.email} (${m.firstName} ${m.lastName})`;
  }
}
