import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router, NavigationStart, NavigationEnd, Event, ActivatedRoute } from '@angular/router';
import { filter, scan } from 'rxjs/operators';
import { RouterHistory } from './router-history.model';
import { HistoryEntry } from './router-history-entry.model';
import { Location, PopStateEvent, LocationStrategy } from '@angular/common';

export type RouterNavigationEvent = NavigationStart | NavigationEnd;

@Injectable( {
  providedIn: 'root'
} )
export class RouterHistoryService {
  public previousUrl$ = new BehaviorSubject<string | null>( null );
  public currentUrl$ = new BehaviorSubject<string | null>( null );
  public browserBack$ = new Subject<PopStateEvent>();
  public browserForward$ = new Subject<PopStateEvent>();

  private currentIndex = 0;
  private historyList: Array<HistoryEntry> = [];
  private historyCallbacks: { [id: string]: Array<() => void> } = {};

  constructor (
    private router: Router,
    private location: Location,
    private locationStrategy: LocationStrategy,
    private activatedRoute: ActivatedRoute
  ) {
    this.location.subscribe( ( event: PopStateEvent ) => {

      const callbackList = this.historyCallbacks[event.state.navigationId];
      if ( callbackList && callbackList.length > 0 ) {
        callbackList[callbackList.length - 1]();
        callbackList.splice( callbackList.length - 1 );
      }
    } );

    this.router.events
      .pipe(
        // only include NavigationStart and NavigationEnd events
        filter<Event, RouterNavigationEvent>(
          ( event: Event ): event is RouterNavigationEvent =>
            event instanceof NavigationStart || event instanceof NavigationEnd
        ),
        scan<RouterNavigationEvent, RouterHistory>(
          ( acc: any, event ) => {
            if ( event instanceof NavigationStart ) {
              // We need to track the trigger, id, and idToRestore from the NavigationStart events
              return {
                ...acc,
                event,
                trigger: event.navigationTrigger,
                id: event.id,
                idToRestore:
                  ( event.restoredState && event.restoredState.navigationId ) ||
                  undefined
              };
            }

            // NavigationEnd events
            const history = [...acc.history];
            let currentIndex = acc.currentIndex;

            // router events are imperative (router.navigate or routerLink)
            if ( acc.trigger === 'imperative' ) {
              // remove all events in history that come after the current index
              history.splice( currentIndex + 1 );

              // add the new event to the end of the history and set that as our current index
              const fullPath = event.urlAfterRedirects.split( '?' );
              history.push( { id: acc.id, url: fullPath[0], queryParams: fullPath.length > 1 ? fullPath[1] : '' } );
              currentIndex = history.length - 1;
            }

            // browser events (back/forward) are popstate events
            if ( acc.trigger === 'popstate' ) {
              // get the history item that references the idToRestore
              const idx = history.findIndex( ( x ) => x.id === acc.idToRestore );

              // if found, set the current index to that history item and update the id
              if ( idx > -1 ) {
                if ( idx > currentIndex ) { /* browsing history forward */ }
                if ( idx < currentIndex ) { /* browsing history backward */ }

                currentIndex = idx;
                history[idx].id = acc.id;
              } else {
                currentIndex = 0;
              }
            }

            return {
              ...acc,
              event,
              history,
              currentIndex
            };
          },
          {
            event: null,
            history: [],
            trigger: null,
            id: 0,
            idToRestore: 0,
            currentIndex: 0
          } as any
        ),
        // filter out so we only act when navigation is done
        filter(
          ( { event, trigger } ) => event instanceof NavigationEnd && !!trigger
        )
      )
      .subscribe( ( { history, currentIndex }: any ) => {
        const previous = history[currentIndex - 1];
        const current = history[currentIndex];
        this.currentIndex = currentIndex;
        this.historyList = history;

        // update current and previous urls
        this.previousUrl$.next( previous ? previous.url : null );
        this.currentUrl$.next( current.url );
      } );

    this.currentUrl$.next( this.router.url );
  }

  public async pushState( title: string, queryParams?: string, url?: string, callback?: () => void ): Promise<void> {
    if ( !url ) {
      if ( this.historyList.length > 0 ) {
        url = this.historyList[this.currentIndex].url;
      } else {
        url = this.router.url;
      }
    }
    if ( !queryParams ) { queryParams = ''; }

    const id = this.historyList.length > 0 ? this.historyList[this.currentIndex].id + 1 : 2;
    this.locationStrategy.pushState(
      { navigationId: id },
      title,
      url,
      queryParams
    );

    this.historyList.push( { id: history.state, url, queryParams } );
    this.previousUrl$.next( this.currentUrl$.getValue() );
    this.currentUrl$.next( url + queryParams );

    if ( callback ) {
      if ( this.historyCallbacks[this.historyList[this.currentIndex].id] ) {
        this.historyCallbacks[this.historyList[this.currentIndex].id].push( callback );
      } else {
        this.historyCallbacks[this.historyList[this.currentIndex].id] = [callback];
      }
    }
  }
}
