import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@signature/webfrontauth';
import { UserService } from 'src/app/shared/services/user.service';

@Component( {
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.less']
} )
export class TopbarComponent implements OnInit {
  public userInitials: string;
  public privateMode: boolean;

  constructor (
    private _router: Router,
    private _authService: AuthService,
    private _userService: UserService
  ) {
    this.userInitials = this._authService.authenticationInfo.user.userName.substring( 0, 1 );
    this.privateMode = false;
  }

  ngOnInit(): void {
  }

  setPrivateMode( bool: boolean ): void {
    this.privateMode = bool;
    this._userService.setPrivateMode( bool );
  }

  goToHome(): void {
    this._router.navigate( [''] );
  }

  async logout(): Promise<void> {
    await this._authService.logout();
    this._router.navigate( ['/login'] );
  }

  shoudShowPrivateMode(): boolean {
    return this._userService.isSignatureUser();
  }
}
