import { NgModule } from '@angular/core';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';

import {
  DashboardFill,
  DashboardOutline,
  RightSquareOutline,
  UnorderedListOutline,
  ArrowRightOutline,
  UndoOutline,
  DeleteOutline,
  EditOutline
} from '@ant-design/icons-angular/icons';

const icons = [
  DashboardOutline,
  DashboardFill,
  UnorderedListOutline,
  RightSquareOutline,
  ArrowRightOutline,
  UndoOutline,
  DeleteOutline,
  EditOutline
];

@NgModule( {
  imports: [NzIconModule],
  exports: [NzIconModule],
  providers: [
    { provide: NZ_ICONS, useValue: icons }
  ]
} )
export class IconsProviderModule {
}
