import { Command } from "@signature/crs-client";

@Command( 'CreateMailingList' )
export class CreateMailingListCommand {
    constructor (
        public actorId: number,
        public projectId: number,
        public mailingListName: string
    ) {
    }
}