import axios from 'axios';
import { IWebSettings } from './websettings.model';

export async function websettingsLoader(): Promise<IWebSettings> {
  const { data, status } = await axios.get<IWebSettings>(
    `/api/config/websettings?time=${new Date().getTime().toString()}`
  );
  if ( status !== 200 ) {
    throw new Error( 'Unable to fetch web settings.' );
  }

  return data;
}