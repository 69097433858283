<div class="profile-container">
    <div class="form-container">
        <form nz-form [formGroup]="pwForm" (ngSubmit)="submitForm()">
            <nz-form-control>
                <nz-form-label>Nouveau mot de passe</nz-form-label>
                <nz-input-group [nzSuffix]="firstTemplate">
                    <input
                      [type]="showPassword ? 'text' : 'password'"
                      nz-input                      
                      formControlName="newPw"
                    />
                  </nz-input-group>
                  <ng-template #firstTemplate>
                    <i nz-icon [nzType]="showPassword ? 'eye' : 'eye-invisible'" (click)="showPassword = !showPassword"></i>
                  </ng-template>
            </nz-form-control>
            <nz-form-control>
                <nz-form-label>Confirmation du mot de passe</nz-form-label>
                <nz-input-group [nzSuffix]="secondTemplate">
                    <input
                      [type]="showRepeatPassword ? 'text' : 'password'"
                      nz-input                      
                      formControlName="repeatPw"
                    />
                  </nz-input-group>
                  <ng-template #secondTemplate>
                    <i nz-icon [nzType]="showRepeatPassword ? 'eye' : 'eye-invisible'" (click)="showRepeatPassword = !showRepeatPassword"></i>
                  </ng-template>
            </nz-form-control>
            <div class="buttons-container">
                <a nzType="link" nz-button (click)="resetForm()">Réinitialiser</a>
                <button [disabled]="!pwForm.valid" nz-button nzType="primary">Valider</button>
            </div>
        </form>
    </div>
</div>