export enum POST_TYPES {
    INFORMATION = 1,
    DECISION = 2,
    ACTION = 3
}

export enum ACTION_STATUS {
    PENDING = 1,
    ONGOING = 2,
    COMPLETED = 3,
    CANCELED = 4
}