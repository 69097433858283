<div class="project-view-container">
    <ng-container *ngIf="projectData">
        <div class="informations">
            <div class="header">
                <div class="project-name">
                    <i nz-icon nzType="arrow-left" nzTheme="outline" (click)="onBack()"></i>
                    <span>{{ projectData.projectName }}</span>
                    <div [ngSwitch]="projectData.projectStatusId" class="tag">
                        <ng-container *ngIf="projectData.isPrivate">
                            <nz-tag nzColor="error">
                                Privé
                            </nz-tag>
                        </ng-container>
                        <ng-container *ngIf="projectData.isArchived">
                            <nz-tag [nzColor]="'red'">
                                Archivé
                            </nz-tag>
                        </ng-container>
                        <ng-container *ngSwitchCase="projectStatus.PENDING">
                            <nz-tag nzColor="default">{{ projectData.projectStatus }}</nz-tag>
                        </ng-container>
                        <ng-container *ngSwitchCase="projectStatus.IN_PROGRESS">
                            <nz-tag [nzColor]="'gold'">{{ projectData.projectStatus }}</nz-tag>
                        </ng-container>
                        <ng-container *ngSwitchCase="projectStatus.COMPLETED">
                            <nz-tag [nzColor]="'#87d068'">{{ projectData.projectStatus }}</nz-tag>
                        </ng-container>

                        <a *ngIf="!editingProjectStatus && !currentUserIsViewer" nz-button nzType="link" (click)="editingProjectStatus = true">
                            Modifier l'état
                        </a>
                    </div>
                    <div class="status-change" *ngIf="allStatus.length > 0 && editingProjectStatus && !currentUserIsViewer">
                        <nz-select [(ngModel)]="selectedStatus">
                            <ng-container *ngFor="let s of allStatus">
                                <nz-option [nzLabel]="s.status" [nzValue]="s.projectStatusId"></nz-option>
                            </ng-container>
                        </nz-select>
                        <button nz-button nzType="primary" (click)="editProjectStatus()">
                            Valider
                        </button>
                        <a *ngIf="editingProjectStatus" nz-button nzType="link" (click)="editingProjectStatus = false">Annuler</a>
                    </div>
                </div>
                <div class="right">
                    <div class="actions">
                        <button *ngIf="!currentUserIsViewer" nz-button nzType="primary" (click)="openEditProjectModal()">
                            <i nz-icon nzType="edit" nzTheme="outline"></i>
                            Modifier le projet
                        </button>
                        <button *ngIf="!projectData.isArchived && !currentUserIsViewer; else archived" nz-button nzType="primary" (click)="archiveProject()">
                            <i nz-icon nzType="delete" nzTheme="outline"></i>
                            Archiver
                        </button>
                        <ng-template #archived>
                            <button *ngIf="!currentUserIsViewer" nz-button nzType="primary" (click)="restoreProject()">
                                <i nz-icon nzType="undo" nzTheme="outline"></i>
                                Restaurer
                            </button>
                        </ng-template>
                    </div>
                    <div class="last-edition">
                        <span>Dernière modification : </span>
                        <span>
                            {{ projectData.editorFirstName }} {{ projectData.editorLastName }} ( {{ projectData.editorUserName }} ) 
                            le {{ formatDate( projectData.lastEditionDate ) }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="cards">
                <div class="description">
                    <nz-card class="card" [nzTitle]="'Description'">
                        <p>{{ projectData.description }}</p>
                    </nz-card>
                </div>
                <div class="members">
                    <nz-card class="card" [nzTitle]="'Membres'" [nzExtra]="extraTemplate">
                        <div class="members-container" *ngIf="projectData.members && projectData.members.length > 0">
                            <ng-container *ngFor="let m of projectData.members">
                                <ng-container *ngIf="!currentUserIsViewer; else viewer">
                                    <div 
                                        [class]="getMemberClass( m )" 
                                        nzTooltipPlacement="top" 
                                        nz-tooltip 
                                        [nzTooltipTitle]="tooltipTemplate"
                                        [nzTooltipTitleContext]="{ $implicit: m }"
                                        (click)="modifyUserGroup( m )">
                                        <span class="initial">{{ m.userName.substring( 0, 1 ) }}</span>
                                        <i nz-icon nzType="edit" nzTheme="outline"></i>
                                    </div>
                                    <ng-template #tooltipTemplate let-user>
                                        {{ getMemberTooltip( user ) }}
                                    </ng-template>
                                </ng-container>
                                <ng-template #viewer>
                                    <div 
                                        [class]="getMemberClass( m )" 
                                        nzTooltipPlacement="top" 
                                        nz-tooltip 
                                        [nzTooltipTitle]="tooltipTemplate"
                                        [nzTooltipTitleContext]="{ $implicit: m }">
                                        {{ m.userName.substring( 0, 1 ) }}
                                    </div>
                                    <ng-template #tooltipTemplate let-user>
                                        {{ getMemberTooltip( user ) }}
                                    </ng-template>
                                </ng-template>
                            </ng-container>
                        </div>
                    </nz-card>
                    <ng-template #extraTemplate>
                        <ng-container *ngIf="!currentUserIsViewer">
                            <button nz-button nzType="primary" (click)="addUserToProject()">
                                <i nz-icon nzType="plus" nzTheme="outline"></i>
                                Ajouter
                            </button>
                            <button nz-button nzType="primary" (click)="handleMailingList()">
                                <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
                                Listes de diffusion
                            </button>
                        </ng-container>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="posts-container">
            <div class="actions" *ngIf="!currentUserIsViewer">
                <button id="add-post" nz-button nzType="primary" (click)="openCreatePostModal()">
                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                    Ajouter un post
                </button>
                <button nz-button nzType="primary" (click)="goToWorksheet()">
                    Générer un rapport
                    <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
                </button>
            </div>
            <app-post-display 
                [infos]="projectData.informations"
                [decisions]="projectData.decisions"
                [actions]="projectData.actions"
                (dataModified)="getProjectData( projectData.projectId )">
            </app-post-display>
        </div>

        <!-- <button nz-button nzType="primary" class="worksheet-btn" (click)="goToWorksheet()">
            <i nz-icon nzType="snippets" nzTheme="outline"></i>
        </button> -->
    </ng-container>
</div>

<app-generic-modal 
    #modalComponent
    (formValidated)="modalFormValidated($event)"
></app-generic-modal>

<nz-modal [(nzVisible)]="isHandlingMembers" [nzTitle]="modalTitle" (nzOnCancel)="cancelHandle()">
    <ng-template #modalTitle>
        <ng-container *ngIf="selectedMember; else addingTitle">
            Modifier le rôle de : {{ selectedMember.userName }}
        </ng-container>
        <ng-template #addingTitle>
            Ajout d'un nouveau membre
        </ng-template>
    </ng-template>
    <ng-container *nzModalContent>
        <ng-container *ngIf="memberForm">
            <ng-container *ngIf="selectedMember; else addingUser">
                <form nz-form [formGroup]="memberForm">
                    <nz-form-item>
                        <nz-form-control>
                            <nz-form-label [nzRequired]="true">Rôle</nz-form-label>
                            <nz-select formControlName="groupId">
                                <nz-option *ngFor="let g of projectGroups" [nzLabel]="g.groupName" [nzValue]="g.groupId"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </form>

                <div class="remove-user" *ngIf="shouldShowRemoveUser()">
                    <button nz-button nzType="primary" nzDanger (click)="removeUser()">Retirer l'utilisateur</button>
                </div>
            </ng-container>
            <ng-template #addingUser>
                <form nz-form [formGroup]="memberForm">
                    <nz-form-item>
                        <nz-form-control>
                            <nz-form-label [nzRequired]="true">Utilisateur</nz-form-label>
                            <nz-select formControlName="userId">
                                <nz-option *ngFor="let u of potentialMembers" [nzLabel]="u.userName" [nzValue]="u.userId"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-control>
                            <nz-form-label [nzRequired]="true">Rôle</nz-form-label>
                            <nz-select formControlName="groupId">
                                <nz-option *ngFor="let g of projectGroups" [nzLabel]="g.groupName" [nzValue]="g.groupId"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </ng-template>
        </ng-container>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="cancelHandle()">Fermer</button>
        <button nz-button nzType="primary" (click)="submitMemberForm()">Valider</button>
    </div>
</nz-modal>

<app-mailing-list-modal [isVisible]="isHandlingMailingList" [projectId]="projectId" (modalClosed)="closeMailingListModal()">
</app-mailing-list-modal>